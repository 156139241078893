<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->


        <!-- 面包屑 -->
        <div style="width: 1200px;height: auto;margin: 30px auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">数控机床一站式服务平台首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/announcement/list' }">网站公告</el-breadcrumb-item>
                <el-breadcrumb-item>公告详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>



        <!-- 内容 -->
        <div class="content">
            <div class="title">{{ data.articleTitle }}</div>
            <div class="time">{{ data.updateTime }}</div>
            <div class="text">
                {{ data.articleContent }}
            </div>
        </div>

        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'info',
    components: {
        largeHead,
        bottomContent
    },
    data() {
        return {
            // 头部激活菜单
            tabArrShow: null,
            data: {}
        };
    },
    mounted() {
        this.data = JSON.parse(this.$route.query.data)
        console.log(this.data)
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 内容
    .content {
        width: 1200px;
        height: auto;
        margin: 30px auto;
        background-color: #fff;
        padding: 40px 0;
        box-sizing: border-box;

        >.title {
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #333;
            text-align: center;
        }

        >.time {
            width: 100%;
            font-size: 12px;
            color: #999;
            text-align: center;
            margin-top: 10px;
        }

        >.text {
            width: 980px;
            height: auto;
            margin: 24px auto;
            color: #333;
            font-size: 14px;
            line-height: 36px;
        }
    }
}


/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}
</style>